<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">User View</h4>
                <div>
                  <b-button variant="red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                  <b-button variant="primary" squared @click="openPasswordModal" size="sm" class="ml-2">Reset Password</b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
         <div v-if="state === 'show'">
           <b-row>
             <b-col cols="4">
               <label>Name <span class="text-red">*</span></label>
               <b-form-input v-model="$v.selectedUser.name.$model" 
                             @blur="$v.selectedUser.name.$touch()"></b-form-input>
               <div v-if="$v.selectedUser.name.$error" class="text-red font-weight-400 text-left">
                 <p v-if="!$v.selectedUser.name.required">This is a required field</p>
               </div>
             </b-col>
             <b-col cols="4">
               <label>Surname <span class="text-red">*</span></label>
               <b-form-input v-model="$v.selectedUser.surname.$model" 
                             @blur="$v.selectedUser.surname.$touch()"></b-form-input>
               <div v-if="$v.selectedUser.surname.$error" class="text-red font-weight-400 text-left">
                 <p v-if="!$v.selectedUser.surname.required">This is a required field</p>
               </div>
             </b-col>
             <b-col cols="4">
               <label>Last Logged in</label>
               <p v-if="this.selectedUser.lastLoginDate != null">{{this.selectedUser.lastLoginDate | dateTimeFilter}}</p>
               <p v-if="this.selectedUser.lastLoginDate == null">N/A</p>
             </b-col>
           </b-row>
           <b-row>
             <b-col cols="4">
               <label>Contact Number</label>
               <b-form-input v-model="$v.selectedUser.contactNumber.$model" 
                             @blur="$v.selectedUser.contactNumber"></b-form-input>
               <div v-if="$v.selectedUser.contactNumber.$error" class="text-red font-weight-400 text-left">
                 <p v-if="!$v.selectedUser.contactNumber.minLength || !$v.selectedUser.contactNumber.maxLength">Please enter a valid number</p>
               </div>
             </b-col>
             <b-col cols="4">
               <label>Email <span class="text-red">*</span></label>
               <b-form-input v-model="$v.selectedUser.email.$model" 
                             @blur="$v.selectedUser.email.$touch"></b-form-input>
               <div v-if="$v.selectedUser.email.$error" class="text-red font-weight-400 text-left">
                 <p v-if="!$v.selectedUser.email.required">This is a required field</p>
                 <p v-if="!$v.selectedUser.email.email">Please enter a valid email address</p>
               </div>
             </b-col>
             <b-col cols="4">
               <label>API Key</label>
               <b-form-input v-model="$v.selectedUser.solarwindsApiKey.$model"
                             @blur="$v.selectedUser.solarwindsApiKey.$touch"></b-form-input>
               <!-- <div v-if="$v.selectedUser.api.$error" class="text-red font-weight-400 text-left">
                 <p v-if="!$v.selectedUser.api.required">This is a required field</p>
                 <p v-if="!$v.selectedUser.api">Please enter a valid API Key</p>
               </div> -->
             </b-col>
           </b-row>
           
           <hr class="mx-3">
           <b-row>
             <b-col cols="4">
               <label>Company</label>
               <b-form-select v-model="selectedUser.nextecCompanyId" @change="companySelected()">
                 <b-form-select-option v-for="(item, index) in companiesList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Region</label>
               <b-form-select v-model="selectedUser.nextecRegionId" :disabled="regionsList.length < 1" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in regionsList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Office</label>
               <b-form-select v-model="selectedUser.nextecOfficeId" :disabled="officesList.length < 1" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in officesList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
           </b-row>

           <b-row>
             <b-col cols="4">
               <label>Role</label>
               <b-form-select v-model="selectedUser.roleId" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in roles" :key="index" :value="item.roleID">{{item.description}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4" v-if="selectedUser.roleId !== 1">
               <label>Supervisor</label>
               <b-form-select v-model="selectedUser.supervisorId">
                 <b-form-select-option v-for="(item, index) in supervisors" :key="index" :value="item.userId">{{item.name}} {{item.surname}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Manager</label>
                <b-input-group>
                    <b-form-input v-model="selectedUser.managerName" :disabled="true"></b-form-input>
                    <b-input-group-append>
                        <b-button size="sm"  text="Button" variant="secondary" @click="openManagerSearchModal">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
             </b-col>
           </b-row>
           <hr class="mx-3">
           <b-row>
             <b-col>
               <b-form-group label="Select rights of access for the user:">
                <b-form-checkbox-group
                  v-model="selectedUser.accessRights">
                  <b-row class="m-0 p-0">
                    <b-col cols="2" class="m-0 p-0" v-for="(item, index) in accessRights" :key="index">
                      <b-form-checkbox :value="item">{{item.value}}</b-form-checkbox>
                    </b-col>
                  </b-row>
                  
                </b-form-checkbox-group>
              </b-form-group>

             </b-col>
           </b-row>
           <hr class="mx-3">
           <b-row>
             <b-col>
               <div class="d-flex justify-content-end">
                 <div>
                   <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                 </div>
                 <div>
                   <b-button variant="primary" squared @click="updateUserDetail" class="ml-2" :disabled="$v.selectedUser.$invalid">Save</b-button>
                 </div>
               </div>
             </b-col>
           </b-row>
         </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="userModal" hide-footer hide-header-close title="Delete User">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span class="font-weight-bold text-red text-center">{{selectedUser.name}} {{selectedUser.surname}}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="red" @click="hideDeleteModal" squared class="mr-2" >Cancel</b-button>
          <b-button variant="red" @click="removeUser" squared >Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
    <b-modal id="passwordModal" hide-footer hide-header-close title="Reset User Password">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are are about to reset <span class="font-weight-bold text-red text-center">{{selectedUser.name}} {{selectedUser.surname}}</span> password</label>
          <label class="text-center" >Please type a new password</label>
        </b-col>
      </b-row>
      <b-row>
        <b-form-input v-model="password"></b-form-input>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="red" @click="hidePasswordModal" squared class="mr-2" >Cancel</b-button>
          <b-button variant="primary" @click="updatePassword" squared >Reset Password</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>

    
    <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="user.name"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Surname</label>
          <b-form-input v-model="user.surname"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Email</label>
          <b-form-input v-model="user.email"></b-form-input>
        </b-col>
      </b-row>

      <hr class="mx-3">
      <b-row>
        <b-col class="text-right">
          <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
          <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
        </b-col>
      </b-row>
        
      <b-row>
        <b-col class="12">
          <b-table striped hover 
            :items="tableDataUsers.dataSource" 
            :fields="tableDataUsers.tableColumns"
            :busy="tableDataUsers.isLoading" 
            @row-clicked="chooseUser"
            :per-page="tableDataUsers.resultsPerPage"
            id="userSearch"
            :current-page="tableDataUsers.currentPage" sort-icon-left>

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      
        <b-row align-h="center" >
            <b-pagination
                v-model="tableDataUsers.currentPage"
                :total-rows="userRows"
                :per-page="tableDataUsers.resultsPerPage"
                aria-controls="userSearch"
            ></b-pagination>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
            </b-col>
        </b-row>
    </b-modal>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {required, email} from "vuelidate/lib/validators";
import md5 from 'js-md5'

export default {
  name: "userView",
  
  data: () => ({
    state: 'loading',
    userData: {
      userId: 0,
      name: null,
      surname: null,
      email: null,
      contactNumber: null,
      title: null,
      roleId: 0,
      lastLoginDate: null,
      hashPassword: '',
      solarwindsApiKey: null,
    },
    roles: [],
    password: null,
    accessRights: [],
    companiesList: [],
    regionsList: [],
    officesList: [],
    user: {
        name: null,
        surname: null,
        email: null
    },
    tableDataUsers: {
        resultsPerPage: 10,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
            label: 'Name',
            key: 'name',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Surname',
            key: 'surname',
            sortable: true,
            tdClass: '',
            },
            {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
            }
        ]
    },
    supervisors: []
  }),
  created() {
    this.getSelectedUser()
    this.userRole()
    this.setBreadcrumb([
      {
        text: 'User'
      },
      {
        text: 'Details'
      },
    ])
    this.getAccessModules()
    .then((res) => {
      this.accessRights = res.data
    })

    this.getCompanyList()
    .then((res) => {
      this.companiesList = res.data
    })
  },
  methods: {
    ...mapActions(["getUserDetail", "updateUser", 'searchUsersRequest', "deleteUser", "getUserRoles", "createNewPassword", 'getAccessModules', 'getCompanyList', 'getRegionList', 'getOfficeList', 'getSupervisors']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    openManagerSearchModal(){
      this.$bvModal.show('search-user-modal')
      this.doSearchUsers()
    },
    closeSearchUserModal(){
      this.$bvModal.hide('search-user-modal')
    },
    chooseUser(rowItem){
      //console.log(rowItem)
      this.selectedUser.managerId = rowItem.userId
      this.selectedUser.managerName = rowItem.name + ' ' + rowItem.surname
      console.log(this.selectedUser)
      this.closeSearchUserModal()
    },
    doSearchUsers(){
        this.tableDataUsers.isLoading = true
  
        let request = []
        
        if (this.user.name != null) {
            request.push({'key': 'name', 'value': this.user.name})
        }
        if (this.user.surname != null) {
            request.push({'key': 'surname', 'value': this.user.surname})
        }
        if (this.user.email != null) {
            request.push({'key': 'email', 'value': this.user.email})
        }
        if (this.user.roleId != null) {
            request.push({'key': 'fkroleId', 'value': this.user.roleId})
        }
        
        this.$store.commit('setUserSearchRequest', request)
        
        this.searchUsersRequest()
        .then((request) => {
            this.tableDataUsers.dataSource = request.data
            
            this.tableDataUsers.isLoading = false
            this.state  = 'show'
        })
        .catch( () => {
            this.tableDataUsers.isLoading = false
        })
    },
    
    clearUsersFilter(){
      this.user = {
          name: null,
          surname: null,
          email: null
      }
    },
    companySelected(){
      this.getRegionList()
      .then((res) => {
        this.regionsList = res.data
      })

      this.getOfficeList()
      .then((res) => {
        this.officesList = res.data
      })
    },

    getSelectedUser() {
      if (this.$store.state.selectedUser == null){
        this.$router.push({path: '/admin/userSearch'})
      }
      this.getUserDetail()
      .then(() => {
        this.state = 'show'

        if (this.selectedUser.nextecCompanyId !== null && this.selectedUser.nextecCompanyId !== 0) {
          this.companySelected()
        }
        if (this.selectedUser.supervisorId !== 0) {
          this.roleSelected()
        }
      })
    },

    updateUserDetail() {
      this.state = "loading"
      // this.$store.commit('setSelectedUser', this.userData)
      this.updateUser()
        .then(() => {
          this.goBackToSearch()
        })
      .catch(() => {
        this.state = 'show'
      })
    },
    
    goBackToSearch(){
      this.$router.push({path: '/admin/userSearch'})
    },
    
    removeUser() {
      this.state = 'loading'
      this.selectedUser.active = false
      // this.$store.commit('selectedUser', this.selectedUser)
      this.deleteUser()
      .then(() => {
        this.$router.push ({path: '/admin/userSearch'})
      })
    },
    roleSelected(){
      //alert(this.selectedUser.roleID)
      if (this.selectedUser.roleId !== 1 && this.selectedUser.roleId !== 0) {
        this.getSupervisors()
        .then((res) => {
          let list = res.data.sort(function(a, b){
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
          });
          this.supervisors = list
        })
      } else {
        this.userData.supervisorId = 0
      }
    },

    userRole() {
      this.getUserRoles()
      .then((response) => {
        this.roles = response.data
      })
    },

    openDeleteModal() {
      this.$bvModal.show('userModal')
    },
    
    openPasswordModal() {
      this.$bvModal.show('passwordModal')
    },

    hideDeleteModal() {
      this.$bvModal.hide('userModal')
    },
    
    hidePasswordModal() {
      this.$bvModal.hide('passwordModal')
    },
    
    updatePassword() {
      var bytes = []; // char codes
      //console.log(this.password)

      for (var i = 0; i < this.password.length; ++i) {
          var code = this.password.charCodeAt(i);
          bytes = bytes.concat([code]);
      }

      let passwordhash = md5.base64(bytes)

      let request = {
        "key": this.selectedUser.userId,
        "value": passwordhash
      }
      
      this.$store.commit('setUserPasswordRequest', request)
      this.createNewPassword()
      .then(() => {
        this.hidePasswordModal()
      })
      .catch(() => {
        this.hidePasswordModal()
      })
      
      this.hidePasswordModal()
    },
    
  },
  computed: {
    ...mapState([
        'selectedUser'
    ]),
    
        userRows() {
            return this.tableDataUsers.dataSource.length
        },
  },
  validations: {
    selectedUser: {
      name: {required},
      surname: {required},
      email: {email, required},    
      contactNumber: {required},
      solarwindsApiKey: {},
    },
  },
}
</script>

<style scoped>

</style>